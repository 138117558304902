import * as React from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { ModelViewer3DViewModel } from "./ModelViewer3DViewModel.csharp";
import { ErrorBoundary } from "react-error-boundary";
import ModelViewer from "./ModelViewer";
import classNames from "classnames";
import { FC } from "react";
import { VacancyBlockViewModel } from "../Vacancy/VacancyBlockViewModel.csharp";

interface ModelViewer3DState {}

export const ModelViewer3D: FC<ComponentProps<ModelViewer3DViewModel>> = ({
  model: { modelUrl, caption, title },
}) => (
  <div>
    {modelUrl ? (
      <figure className={classNames("Figure", "ModelViewerFigure")}>
        <h2 className={"Section__title"}>{title}</h2>
        <ErrorBoundary fallback={<p>3D model preview unavailable</p>}>
          <div className="ModelViewerFigure__ModelContainer">
            <ModelViewer
              src={modelUrl}
              ar-modes="scene-viewer webxr quick-look"
              camera-controls
              tone-mapping="neutral"
              shadow-intensity="1"
              shadow-softness="1"
              touch-action="pan-y"
              data-ar
              ar-status="not-presenting"
              className="modelViewer"
              style={{ width: "100%", height: "90%" }}
            ></ModelViewer>
          </div>
          {caption && <figcaption className="Figure__caption">{caption}</figcaption>}
        </ErrorBoundary>
      </figure>
    ) : (
      <></>
    )}
  </div>
);
