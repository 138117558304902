import React, { FC } from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { SectionsNavbar } from "../../../react-components/SectionsNavbar/SectionsNavbar";
import { NavbarProps } from "../../../Common/Navbar/NavbarProps.csharp";

export const ProductNavbar: FC<ComponentProps<NavbarProps>> = ({
  model: { sections, translations },
}) => {
  return <SectionsNavbar sections={sections} translations={translations} itemsColor="teal" />;
};
