import React, { useEffect, useState } from "react";

interface ModelViewerProps extends React.HTMLAttributes<HTMLElement> {
  src: string;
  alt?: string;
  autoRotate?: boolean;
  cameraControls?: boolean;
}

const loadModelViewerScript = () => {
  const modelViewerPkgUrl = "https://unpkg.com/@google/model-viewer@4.0.0/dist/model-viewer.min.js";

  // Check if the script is already added
  if (!document.querySelector(`script[src="${modelViewerPkgUrl}"]`)) {
    const script = document.createElement("script");
    script.src = modelViewerPkgUrl;
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);
  }
};

// eslint-disable-next-line react/display-name
const ModelViewer = React.forwardRef<HTMLElement, ModelViewerProps>(
  ({ src, alt, autoRotate, cameraControls, ...props }, ref) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      // Does not run in SSR
      loadModelViewerScript();
      setIsClient(true);
    }, []);

    if (!isClient) {
      return <div>Loading 3D model...</div>;
    }

    return (
      <model-viewer
        ref={ref}
        src={src}
        alt={alt}
        auto-rotate={autoRotate ? "" : undefined}
        camera-controls={cameraControls ? "" : undefined}
        {...props}
      ></model-viewer>
    );
  },
);

export default ModelViewer;
