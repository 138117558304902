import React, { FC } from "react";
import { UpperContentProps } from "./UpperContentProps.csharp";
import { ComponentProps } from "../../ComponentProps.csharp";
import Clock from "../../../../wwwroot/Static/Images/Icons/Clock.svg";
import Marker from "../../../../wwwroot/Static/Images/Icons/Marker.svg";

export const UpperContent: FC<ComponentProps<UpperContentProps>> = ({
  model: { subtitle, leftSegmentText, rightSegmentText },
}) => {
  return (
    <div className="UpperContent">
      {subtitle && <h3 className="UpperContent__subtitle">{subtitle}</h3>}
      <div className="UpperContent__additionalInfo">
        {leftSegmentText && (
          <div className="UpperContent__segment">
            <Clock className="UpperContent__icon UpperContent__strokeIcon" /> {leftSegmentText}
          </div>
        )}
        {rightSegmentText && (
          <div className="UpperContent__segment">
            <Marker className="UpperContent__icon UpperContent__fillIcon" /> {rightSegmentText}
          </div>
        )}
      </div>
    </div>
  );
};
